<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>{{ messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>{{ messagetype.title_jp }}</h2>
      <div class="mascot_wrap">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link :to="{ name: 'Front top' }">ホーム</router-link>
            </li>
            <li v-if="showmessage.type == 'thanks'">
              <span>{{ messagetype.title_jp }}一覧</span>
            </li>
            <li v-else>
              <router-link
                :to="{
                  name: 'Front archive',
                  params: { type: message_type },
                  query: {
                    row_count: this.$route.query.row_count
                      ? this.$route.query.row_count
                      : 25,
                  },
                }"
                >{{ messagetype.title_jp }}一覧</router-link
              >
            </li>
            <li
              v-if="
                showmessage.type == 'challenge' || showmessage.type == 'sdgs'
              "
            >
              <span> {{ showYear(showmessage.option) }}</span>
            </li>
            <li v-else>
              <span>{{ showmessage.title }}</span>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div class="post_header">
              <div class="post_header_icon">
                <img :src="iconPath" :alt="messagetype.title_jp" />
              </div>
              <div class="post_header_day">
                <span class="post_header_day_time">
                  <img
                    src="@/assets/front_component/images/assets/board01.png"
                    alt="時間"
                  />
                  <time
                    class="en"
                    :datetime="formatDate_time(showmessage.published_at)"
                    >{{ formatDate(showmessage.published_at) }}</time
                  >
                </span>
                <span
                  v-if="showmessage.from_admin && showmessage.type != 'award'"
                  class="post_header_day_name"
                  >投稿者：{{ showmessage.from_admin }}</span
                >
                <span
                  v-if="showmessage.type == 'award'"
                  class="post_header_day_name"
                  >投稿者：めぐる君</span
                >
                <span v-else class="post_header_day_name"
                  >投稿者：{{ from_user.last_name }}
                  {{ from_user.first_name }}</span
                >
                <span
                  class="post_header_day_name link_ttl"
                  v-if="showmessage.tags && showmessage.type == 'daily'"
                  >タグ：
                  <small
                    class="post_header_day_name"
                    v-for="tag in showmessage.tags"
                    :key="tag.id"
                    >{{ tag.name }}</small
                  >
                </span>

                <p
                  class="post_header_day_name"
                  v-if="showmessage.thanks_type && showmessage.type == 'thanks'"
                >
                  タイプ：<span
                    v-for="thanks_type in showmessage.thanks_type"
                    :key="thanks_type.id"
                    class="thanks_type"
                    >{{ thanks_type.value }}</span
                  >
                </p>
              </div>
            </div>
            <article class="post_area">
              <h1
                v-if="
                  showmessage.type == 'challenge' || showmessage.type == 'sdgs'
                "
              >
                {{ showYear(showmessage.option) }}
              </h1>
              <h1 v-else>{{ showmessage.title }}</h1>

              <div
                v-if="
                  showmessage.type == 'challenge' || showmessage.type == 'sdgs'
                "
                style="min-height: 300px"
              >
                <div class="season_wrap">
                  <h3>目標内容１</h3>
                  <div v-html="showmessage.content"></div>
                </div>
                <div class="season_wrap">
                  <h3>目標内容２</h3>
                  <div v-html="showmessage.content_1"></div>
                </div>
                <div class="season_wrap">
                  <h3>目標内容３</h3>
                  <div v-html="showmessage.content_2"></div>
                </div>
              </div>
              <div
                v-else
                style="min-height: 300px"
                v-html="showmessage.content"
              ></div>

              <div v-if="files.length > 0">
                <h3>関係ファイルダウンロード</h3>
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in files" :key="file.id">
                    <div class="doc_left">
                      <p>{{ file.display_name }}</p>
                    </div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a
                          v-on:click="dlfile(file.storage_file_name)"
                          class="en"
                          target="_blank"
                          :download="file.updaload_name"
                          >DL</a
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </article>

            <Form
              v-if="$route.params.type == 'daily'"
              enctype="multipart/form-data"
              autocomplete="off"
              @submit="register"
              v-slot="{ errors }"
            >
              <div class="reply_area" v-if="reply_messages.data != 0">
                <div
                  class="reply_wrap"
                  v-for="(message, index) in reply_messages"
                  :key="message.id"
                  :id="'reply' + (index + 1)"
                >
                  <p class="res_head">
                    <span class="res_num en">{{ index + 1 }}</span
                    ><span class="en">.</span>
                    <span class="res_name" v-if="message.create_user"
                      >{{ message.create_user.last_name }}
                      {{ message.create_user.first_name }}</span
                    >
                    <span class="res_day_time">
                      <img
                        src="@/assets/front_component/images/assets/board01.png"
                        alt="時間"
                      />
                      <time
                        class="en"
                        :datetime="formatDate_time(message.published_at)"
                        >{{ formatDate(message.published_at) }}</time
                      >
                    </span>
                  </p>
                  <p v-if="message.reply_com_id" class="p_t10">
                    <a :href="'#reply' + message.reply_com_id"
                      >>> {{ message.reply_com_id }}</a
                    >
                  </p>
                  <div class="post_area">
                    <p v-html="htmlText(message.content)"></p>
                  </div>
                  <div class="control_area" v-if="reply_possible">
                    <a
                      href="#reply_textarea"
                      class="reply_button"
                      v-on:click="reply_com(index + 1)"
                    >
                      <div class="icon_wrap">
                        <img
                          src="@/assets/front_component/images/assets/reply01.png"
                          alt="返信"
                          class="mouse_out"
                        />
                        <img
                          src="@/assets/front_component/images/assets/reply02.png"
                          alt="返信"
                          class="mouse_over"
                        />
                      </div>
                      <span class="txt">返信</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="com_area" id="reply_textarea" v-if="reply_possible">
                <div class="com_wrap">
                  <p class="com_name">
                    {{ $User.last_name }} {{ $User.first_name }}
                  </p>
                  <Field
                    name="title"
                    type="hidden"
                    class="form-control"
                    :class="{ 'is-invalid': errors.title }"
                    v-model="create_message.title"
                  />
                  <ErrorMessage class="each_msg" name="title" />
                  <p class="reply_target p_b5">
                    <span>返信先：>> </span
                    ><span class="reply_target_num">1</span
                    ><span class="remove_reply" v-on:click="reply_com_remove()"
                      >×</span
                    >
                  </p>
                  <textarea
                    rows="5"
                    placeholder="コメントを入力してください。"
                    v-model="create_message.content"
                  ></textarea>
                  <Field
                    name="content"
                    type="hidden"
                    class="form-control"
                    placeholder="コメントを入力してください。"
                    :class="{ 'is-invalid': errors.content }"
                    v-model="create_message.content"
                  />
                  <Field
                    name="parent_id"
                    type="hidden"
                    class="form-control"
                    :class="{ 'is-invalid': errors.title }"
                    v-model="create_message.parent_id"
                  />
                  <div class="t_r">
                    <div class="btn_norm">
                      <button type="submit" class="btn btn-primary">
                        送信
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <div class="t_c m_t100 m_b50">
              <div class="btn_wide blue">
                <a style="cursor: pointer" @click="gotoBack()">もどる</a>
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/bard01.png"
              alt="青鳥"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/bard02.png"
              alt="緑鳥"
            />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >アイディアカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import message from "@/apis/Message";
import user from "@/apis/User";
import dayjs from "dayjs";
import MessageType from "@/const/MessageType.json";
import { Form, Field, ErrorMessage } from "vee-validate";

import "@/assets/front_component/js/functions.js";
import $ from "jquery";

export default {
  name: "message",
  data() {
    return {
      //-----dailyのコメント関連-----//
      reply_messages: [],
      create_message: {
        title: "気づき日報の返信コメント",
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        type: "daily_res",
        content_type: "HTML",
        public: "非公開",
        status: "公開",
        parent_id: this.$route.params.id,
      },
      reply_com_id: "",
      reply_possible: true,
      //-----コメント関連終わり-----//

      iconPath: "",
      showmessage: {},
      from_user: {},
      files: {},
      MessageType() {
        return MessageType;
      },
      loader: false,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    console.log("call message");
    this.message_type = this.$route.params.type;
    this.message_id = this.$route.params.id;
    this.showmessageViewPopup(this.message_id);
    this.messagetype = MessageType[this.message_type];
    this.iconPath = require("@/assets/front_component/images/assets/" +
      this.messagetype.imgPath);
    this.getFile();
    if (this.message_type == "daily") {
      this.replyList(this.message_id);
    }
  },
  methods: {
    register() {
      message
        .register(this.create_message)
        .then(
          (response) => {
            if (this.reply_com_id) {
              this.postMessageMeta(
                "reply_com",
                this.reply_com_id,
                "返信コメントへの返信",
                response.data.data.id
              );
            }
            this.$router.push({
              name: "Front show",
              params: { type: "daily", id: this.message_id },
            });
            location.reload(true);
          },
          (error) => {
            console.log(error);
            // this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    postMessageMeta(key, value, description, id) {
      var post_json = {
        key: key,
        value: value,
        description: description,
      };
      message
        .register_meta(post_json, id)
        .then(
          (response) => {
            console.log("meta");
          },
          (error) => {
            console.log("metaerror");
            console.log(error);
            // this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log("metaerrorcatch");
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    reply_com(id) {
      this.reply_com_id = "";
      this.reply_com_id = id;
      console.log(this.reply_com_id);
      $(".reply_target").show();
      $(".reply_target_num").text(this.reply_com_id);
    },
    reply_com_remove() {
      this.reply_com_id = "";
      console.log(this.reply_com_id);
      $(".reply_target").hide();
      $(".reply_target_num").text("");
    },
    replyList(id) {
      this.loader = true;
      message
        .listChild(id, "daily_res")
        .then(
          (response) => {
            if (response != null) {
              this.reply_messages = response.data.data;

              this.reply_messages.forEach((ob, i) => {
                user
                  .show(ob.act_users[0].user_id) //投稿者の取得
                  .then((response) => {
                    if (response != null) {
                      this.reply_messages[i].create_user = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});

                message
                  .getMeta(ob.id) //metaの取得
                  .then((response) => {
                    if (response != null) {
                      if (response.data.data[0]) {
                        this.reply_messages[i].reply_com_id =
                          response.data.data[0].value;
                        console.log(response.data.data[0].value);
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              });

              console.log(this.reply_messages);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            // this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    htmlText(msg) {
      return msg.replace(/\r?\n/g, "<br>");
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    showmessageViewPopup(id) {
      // showmessage
      this.loader = true;
      message
        .show(id)
        .then(
          (response) => {
            if (response != null) {
              if (
                response.data.data.status != "公開" &&
                response.data.data.status != "承認済" &&
                this.message_type !== "challenge" &&
                this.message_type !== "sdgs"
              ) {
                this.$router.push({ name: "Front 404" });
              }

              this.showmessage = response.data.data;

              // for aprovel user in column and photolog screen
              this.showmessage.from_user = "";
              this.showmessage.act_users.forEach((user) => {
                if (user.act_type == "作成") {
                  this.getFromUser(user.user_id);
                }
              });

              //Jquery
              // youtubeがタグの構成上表示出来ないので以下でタグを変換している
              $(function () {
                $("figure.media").each(function () {
                  var $y_url = $(this)
                    .children()
                    .attr("url")
                    .replace("https://youtu.be/", "");
                  $y_url = $(this)
                    .children()
                    .attr("url")
                    .replace("https://www.youtube.com/watch?v=", "");
                  $(this)
                    .children()
                    .replaceWith(
                      "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
                    );
                  console.log($y_url);
                  $(this)
                    .children(".post_youtube")
                    .attr("src", "https://www.youtube.com/embed/" + $y_url);
                });
              });

              // created users only need to show the list
              // if (this.showmessage.act_users[0]["user_id"] != null) {
              //   this.getFromUser(this.showmessage.act_users[0]["user_id"]);
              // }

              // MFUX-1323 気づき日報はログインユーザと同じ部署の人はだれでもコメント投稿を可能にする

              // if (this.showmessage.act_users[0]["user_id"] == this.$User.id) {
              //   this.reply_possible = true;
              // }
              // this.showmessage.target_ids.forEach((ob) => {
              //   if (ob == this.$User.id) {
              //     this.reply_possible = true;
              //   }
              // });

              if (
                this.message_type == "challenge" ||
                this.message_type == "sdgs"
              ) {
                this.showmessage.option = "";
                var contentSplit = JSON.parse(this.showmessage.content);
                var TermoOption = contentSplit;
                this.showmessage.option = TermoOption["option"];
                this.showmessage.content = TermoOption["content"];
                this.showmessage.content_1 = TermoOption["content_1"];
                this.showmessage.content_2 = TermoOption["content_2"];
              }
              if (this.message_type == "thanks") {
                message
                  .showmetakey(id, "key", "thanks_type")
                  .then((response) => {
                    if (response != null) {
                      this.showmessage.thanks_type = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              }
              message
                .showmetakey(id, "key", "from_admin")
                .then((response) => {
                  if (response != null) {
                    this.showmessage.from_admin = response.data.data[0].value;
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {});
              console.log(this.showmessage);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            // this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getFromUser(user_id) {
      user
        .show(user_id)
        .then((response) => {
          if (response != null) {
            this.from_user = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    getFile() {
      message
        .getFile(this.$route.params.id)
        .then(
          (response) => {
            if (response != "") {
              this.files = response.data.data;
              console.log(this.files);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            // this.$router.push({ name: "Front Error" });
          }
        )
        .catch(() => {
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    dlfile(path) {
      this.windowReference = window.open();
      //let fileApiPass = '/api/v1/file/adedd8819fecb6f2abd2756a7771803d?attachment';
      let fileApiPass = "/api/v1/file/" + path;
      console.log(fileApiPass);
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          //console.log(response.headers['content-filename']);
          //console.log(response.headers['content-type']);
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          this.windowReference.location = window.URL.createObjectURL(blob);
          /**
           * 疑似ダウンロード
           */
          //let link= document.createElement('a')
          //link.href= window.URL.createObjectURL(blob)
          //link.download= response.headers['content-filename']
          // // 以下コメントアウトで実行
          // link.click()
        });
    },
    showYear(year) {
      if (typeof year == "number") {
        year = year.toString();
      }

      let setYear = "";
      switch (year) {
        case "2022":
          setYear = "49期 (2022)";
          break;
        case "2023":
          setYear = "50期 (2023)";
          break;
        case "2024":
          setYear = "51期 (2024)";
          break;
        case "2025":
          setYear = "52期 (2025)";
          break;
        case "2026":
          setYear = "53期 (2026)";
          break;
        default:
          setYear = year;
          break;
      }

      return setYear;
    },
    gotoBack() {
      if (window.history.state.back == "/") {
        this.$router.push({
          name: "Front top",
        });
      } else if (this.message_type == "thanks") {
        this.$router.push({
          name: "Front user edit",
          hash: "#target_area",
        });
      } else {
        this.$router.push({
          name: "Front archive",
          params: { type: this.message_type },
          query: {
            row_count: this.$route.query.row_count
              ? this.$route.query.row_count
              : 25,
          },
        });
      }
    },
  },
  mounted() {

    document.body.className = "page_template";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      $(function () {
        var ua = navigator.userAgent;
        $(document).on(
          "click",
          'a[href^="#"]:not(".pop-modal"):not(".news-modal")',
          function () {
            var speed = 550;
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? "html" : href);

            if (
              ua.indexOf("iPhone") > 0 ||
              (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
            ) {
              var position = target.offset().top - 0; //ヘッダの高さ分位置をずらす
            } else {
              position = target.offset().top - 110; //ヘッダの高さ分位置をずらす
            }

            $("html, body").animate({ scrollTop: position }, speed, "swing");
            return false;
          }
        );
      });
    });
  },
  updated() {
    console.log("アップデート");
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
